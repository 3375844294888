import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Entra a far parte della comunità FISH MED NET</h2>
      <h3>Contatta gli sportelli di consulenza</h3>
      <p>
        FISH MED NET mira a raggiungere il maggior numero possibile di comunità di pescatori. Crediamo che la cooperazione tra tutti i pescatori coinvolti sia essenziale per condurre un'operazione di successo e possa solo beneficiare se questa comunità si prende cura l'uno dell'altro. Una delle responsabilità del counseling desk è quella di fornire informazioni su come entrare a far parte di questa comunità e diventare un modello per altri pescatori che aspirano a condurre la loro attività in completa armonia con la loro comunità locale, il tutto nel rispetto degli SDG delle Nazioni Unite.
      </p>
      <p>
        Per partecipare a questa iniziativa vi è un unico requisito, ovvero l'adesione volontaria alle linee guida sviluppate ed identificate dal progetto. 
        Queste linee guida sono indicate all'interno di un accordo di alleanza commerciale e mirano a rispettare l'ambiente e lo stato socio-economico in cui 
        sono coinvolte a livello locale. Infatti, nell'ambito del progetto è stato sviluppato un accordo di alleanza commerciale, non vincolante e per cui non 
        sono richieste commissioni di partecipazione, che verrà consegnato una volta che un'impresa 
        sarà pronta a partecipare a questa iniziativa. Aderendo e rispettando queste regole volontarie FISH MED NET mira a creare un ambiente collaborativo migliore!
      </p>

      <h2>Come aderire?</h2>
      <p>
        Lo sportello di consulenza sarà in grado di assistervi fornendovi la documentazione necessaria per registrare la vostra struttura, nonché, qualsiasi altra informazione di cui potreste aver bisogno al fine di comprendere appieno come FISH MED NET vuol creare un ambiente di lavoro migliore. Una componente chiave del desk di consulenza è spiegare come le alleanze commerciali possono aiutare a potenziare la tua attività e iniziare questo ambiente positivo di cooperazione.
      </p>
      <p>
        Per manifestare il tuo interesse ti preghiamo di:
        <ul>
          <li>Contattare lo sportello di consulenza più vicino a te in modo che il tuo interesse possa essere preso in considerazione.</li>
          <li>Ti contatteremo e ti chiederemo una prova di essere una MPMI registrata nel tuo comune. </li>
          <li>Una volta che tutti i documenti sono stati controllati e sono in regola, vi forniremo:
            <ul>
              <li>Un modulo da compilare che raccoglierà le informazioni di base relative esclusivamente alla tua attività commerciale, e </li>
              <li>Accordo di alleanza commerciale che richiede l'espressione volontaria dell'impresa di rispettare e aderire alle regole citate. Questo dovrà essere firmato e rispedito all'ufficio.</li>
              <li>Una volta ricevuta tutta la documentazione dall'ufficio competente, l'impresa verrà inserita nel database.</li>
            </ul>  
          </li>
        </ul>
      </p>

      <h2>I nostri sportelli di consulenza</h2>
      <table>
        <tr>
          <th>Country</th><th>Region</th><th>Address</th><th>Working hours</th><th>Contacts</th>
        </tr>
        <tr>
          <td>France</td><td>Corsica</td><td>-</td><td>-</td><td><a href="mailto:FMNCorsicaFR@gmail.com">FMNCorsicaFR@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Liguria</td><td>-</td><td>-</td><td><a href="mailto:fmnliguria@gmail.com">fmnliguria@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Puglia</td><td>-</td><td>-</td><td><a href="mailto:fmnpugliait@gmail.com">fmnpugliait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Sardegna</td><td>-</td><td>-</td><td><a href="mailto:fmnsardegnait@gmail.com">fmnsardegnait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Tunisia</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNTunisieTN@gmail.com">FMNTunisieTN@gmail.com</a></td>
        </tr>
        <tr>
          <td>Lebanon</td><td>Beirut</td><td>Ministry of Agriculture, Bir Hassan, Embassy street</td><td>Wednesday 11:00 A.M.-13:00 P.M.</td><td><a href="mailto:FMNLebanonlb@gmail.com">FMNLebanonlb@gmail.com</a><br/><strong>Phone number:</strong>9613268678 - 9611849622</td>
        </tr>
        <tr>
          <td>Palestine</td><td></td><td>-</td><td>-</td><td><a href="mailto:fmngazapal@gmail.com">fmngazapal@gmail.com</a></td>
        </tr>
      </table>

      <h2>Esplora tra le ultime proposte</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
